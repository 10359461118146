body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  border-bottom: 1px solid #DAE0E7;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #ffffff;
  position: fixed;
  z-index: 2;
  width: 100%;
}

.title-search-wagons {
  margin: auto;
  font-weight: 600;
  font-size: 1.5rem;
  color: #404A59;
}

.menu {
  left: 29%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  list-style: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #DAE0E7;
  border-radius: 6px;
  margin-top: 8px;
}

.menu label {
  padding-left: 8px;
}

.search-bar {
  border: 1px solid #DAE0E7;

  border-radius: 6px;

  background-color: #fff;
  height: 1.75rem;
  width: 20rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  height: 48px;

  margin-left: 1rem;
}

.to-trucks {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 2rem ;
  background-color: #ffffff;
  border: 1px solid #DAE0E7;
  border-radius: 6px;
  font-size: 1rem;
  height: 65px;
  width: 20rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  
}

.search-bar::placeholder {
  opacity: 0.75;
}

.search-bar:focus {
  border: 1px solid #707070; 
}

.buttons {
  display: flex;
  position: relative;
}

.react-datepicker-wrapper {
  width: 144px !important;
} 

.react-datepicker__triangle {
  opacity: 0;
}

.date-picker {
  background-color: #ffffff;
  border: 1px solid #DAE0E7;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  height: 2.75rem;
  width: 216px;
  padding-left: 12px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  user-select: none;
  cursor: pointer;
}

.date-picker:hover {
  background-color: #F9FAFA;
}

.download-excel {
  margin-left: 1rem;
  background-color: #0ac799;
  color: #fff;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  height: 3rem;
  width: 10rem;
  padding: 0.5rem;
  margin-right: 3.75rem;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.download-excel:hover {
  background: #09B38B;
}

.date-picker::placeholder {
  color: #000;
}

.icon-wrapper {
  position: absolute;
  top: 29%;
  left: 31%;
  opacity: 0.75;
}

.excel-icon {
  padding-right: 12px;
}

.dropdown button {
  margin-left: 7rem;
  background-color: #ffffff;
  border: 1px solid #DAE0E7;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  height: 3rem;
  width: 10rem;
  padding: 0.5rem;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #F9FAFA;
}

.dropdown-icon {
  padding: 0 12px 0 0;
}

textarea:focus, .search-bar:focus{
  outline: none;
}

.title-founded-wagons {
  font-size: 2rem;
  display: flex;
  margin: 2rem 1rem 2rem 1rem;
  background-color: #ffffff;
  border: 1px solid #DAE0E7;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  padding: 8px 16px;
  height: 48px;
  align-items: center;
  width: 16rem;
  justify-content: center;
}

.div-wagon-results {
  margin-left: 1rem;
  background-color: #fff;
  padding: 0rem 0rem;
  border-radius: 8px;
  height: 76%;
  width: 98%;
}

table {
  border-collapse: separate;
  align-items: center;
  width: 100%;
}

th {
  padding: 20px 0;
}

td {
  margin-top: 150px;
}

td:first-child {
  font-weight: 500;
}

.table-head {
  justify-content: space-between;
  text-align: center;
}

.table-image {
  width: 128px;
  border-radius: 2px;
}

.not-weighted {
  font-weight: bold;
  color: #E31B46;
}

.wagon-item {
  margin-left: 2.5%;
  margin-top: 1%;
}

.container-header {
  display: flex;
  align-items: center;
  padding-top: 5rem;
}

.container-header strong {
  color: #404A59;
  font-size: 1.2rem;
  font-weight: 600;
}

.container-header .checkbox {
  padding-right: 0;
}

.container-header {
  display: flex;
  align-items: center;
}

.table-head:first-child td {
  font-size: 1rem;
  /* border-right: 1px solid #DAE0E7; */
}

.table-head td {
  font-size: 1rem;
  /* border-right: 1px solid #DAE0E7; */
}

.table-head {
  background-color: #fff;
  border: 1px solid #DAE0E7;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.175);
}

.table-head:hover {
  background-color: #F7F7F8;
  border: 1px solid #DAE0E7;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

td {
  padding: 8px;
}

table {
  border-spacing: 0 1rem;
}

::-webkit-scrollbar {
  width: 8px; /* largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* cor de fundo da área da barra de rolagem */
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: #bcbfc5; /* cor da barra de rolagem */
  border-radius: 16px;
}

table {
  padding-right: 1rem;
}

.table-header {
  background-color: #fff;
  padding-bottom: 1.4rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}